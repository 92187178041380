import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";

// Css styles
import "./assets/styles/styles.scss";
import "vue-toast-notification/dist/theme-sugar.css";

// install the vue plugin
Vue.use(VueToast, {
  position: "bottom-right",
  duration: 5000,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
